<template>
  <div class="sccontent">
    <div v-if="pubilcArr.length>0">
      <list :pubilcArr="pubilcArr" v-if="types == 1"></list>
      <btn :pubilcArr="pubilcArr" v-else-if="types == 2"></btn>
    </div>
    <div v-if="pubilcArr.length<1 && types != 3">
      <nodata></nodata>
    </div>
    <tabdetails v-if="types == 3"></tabdetails>
  </div>
</template>
<script>
import btn from '@/views/tabstyle/tabbtn/tabbtn.vue'
import list from '@/views/tabstyle/tablist/tablist.vue'
import tabdetails from "@/views/tabstyle/tabdetails/tabdetails.vue";
import { getServiceContentByColumnId,getContentByColumnId,getChildColumn,getChildServiceColumn } from "@/api/home.js";
export default {
  name:"sccontent",
  components:{
    btn,
    list,
    tabdetails
  },
  data(){
    return{
      pubilcArr:[],
      types:""
    }
  },
  computed:{
    type(){
      return this.$store.state.tablistType
    },
    tabList(){
      return this.$store.state.tabType
    },
    menuType(){
      return this.$store.state.menuType
    }
  },
  watch:{
    tabList:{
      handler(){
         if(this.tabList){
            this.init()
          }
      },
      deep:true,
      immediate: true
    }
  },
  created(){
    // this.init()
  },
  methods:{
    async init(){
      if(this.tabList.columnType == 1){
         if(this.tabList.columnAttr == 1){
            this.$store.commit('setStyleType',5)
            this.$store.commit('settabId',this.tabList.id)
            this.$store.commit('setListId',this.tabList.listType)
            this.types = this.type
        }else{
          this.$store.commit('setStyleType',1)
          
          if(this.menuType == 'lanmu'){
            let data = await getContentByColumnId({
              "columnId": this.tabList.id,
              "pageNumber": 1,
              "pageSize": 90071992
            })
            this.types = this.type
            this.$store.commit('settabId',data.data.data.rows[0].id)
          }else{
            let data = await getServiceContentByColumnId({
              "columnId": this.tabList.id,
              "pageNumber": 1,
              "pageSize": 90071992
            })
            this.types = this.type
            this.$store.commit('settabId',data.data.data.rows[0].id)
          }
        }
      }else{
        if(this.menuType == 'lanmu'){
          let data = await getChildColumn({
            "pageNumber": 1,
            "pageSize": 100,
            "parentId": this.tabList.id
          })
          this.types = this.type
          this.pubilcArr = data.data.data.rows
        }else{
          let data = await getChildServiceColumn({
            "pageNumber": 1,
            "pageSize": 100,
            "parentId": this.tabList.id
          })
          this.types = this.type
          this.pubilcArr = data.data.data.rows
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.sccontent{
  margin-top: 1.2rem;
}
</style>