<template>
  <div class="list">
    <div v-if="pubilcArr.length<1">
      <nodata></nodata>
    </div>
    <div class="listNav" v-for="(item) in pubilcArr" :key="item.id" :style="{backgroundImage:'url(' + item.columnIcon+ ')', 
        backgroundRepeat:'no-repeat',backgroundSize: '100% 100%'}" @click="onlist(item)" v-else>
    </div>
  </div>
</template>
<script>
export default {
  name:"list",
  props:["pubilcArr"],
  data() {
    return {
      list:[
        {img:'https://img01.yzcdn.cn/vant/logo.png'},
        {img:'https://img01.yzcdn.cn/vant/logo.png'},
        {img:'https://img01.yzcdn.cn/vant/logo.png'},
        {img:'https://img01.yzcdn.cn/vant/logo.png'},
        {img:'https://img01.yzcdn.cn/vant/logo.png'},
        {img:require('@/assets/gdyx.png')},
        {img:require('@/assets/gdyx.png')},
        {img:require('@/assets/gdyx.png')}
      ]
    }
  },
  watch:{
  },
  computed:{
  },
  created(){
  },
  methods:{
      onlist(item){
        if(item.columnType == 3){
            location.href = item.columnLink         
           // lightAppJssdk.weex.openWeexVc({
          //   title :item.columnName,//顶部导航栏标题
          //   url : item.columnLink, //weex页面地址
          //   success: function(data) {
          //       console.log(data);
          //   },
          //   fail: function(data) {
          //       console.log('falied');
          //   }
          //   });
        }else{
           // 首页数据
          this.$store.commit('setMenulist',item)
          // 传递id
          this.$router.push({path:'/chooseList',query:{categoryId:item.id,columnType:item.columnType,columnAttr:item.columnAttr,columnStyle:item.columnStyle}})
        }
      }
  }
}
</script>
<style lang="less" scoped>
.list{
  .listNav{
    width: 9.164rem;
    margin: 0 auto;
    height: 2.32rem;
    background-size: 100% 100%;
    margin-bottom: .507rem;
    text-align: left;
    padding-left: .684rem;
    padding-top: .513rem;
    box-sizing: border-box;
    color: #FFFFFF;
  }
  .listNav:nth-child(1){
    margin-top: .271rem;
  }
  .higherLearning{
    font-size: .444rem;
    font-weight: 500;
  }
  .talent{
    font-weight: 400;
    font-size: .356rem;
    margin-top: .256rem;
  }
}
</style>