<template>
      <div class="nkdetails">
          <!-- {{pubilcArr}} -->
          <!-- <div v-if="pubilcArr.length<1" style="position: relative;">
            <nodata></nodata>
          </div> v-else -->
          <div>
            <list v-if="state == 2 && pubilcArr.length>1" :pubilcArr="pubilcArr"></list>
            <btn v-if="state == 3 && pubilcArr.length>1" :pubilcArr="pubilcArr"></btn>
            <tab v-if="state == 1 && pubilcArr.length>1" :pubilcArr="pubilcArr"></tab>
          </div>
      </div>
</template>
<script>
import list from '@/components/liststyle/list'
import btn from '@/components/liststyle/btn'
import tab from '@/components/liststyle/tab'
import { getChildColumn,getChildServiceColumn} from '@/api/home.js';
export default {
  name:"nkdetails",
  components:{
    list,
    btn,
    tab
  },
  data(){
    return{
      pubilcArr:[]
    }
  },
  computed:{
    state(){
      return this.$store.state.columnStyle
    },
    menuType(){
      return this.$store.state.menuType
    },
    contentId(){
      // console.log(this.$store.state.detailsId)
      return this.$store.state.detailsId
    },
  },
  watch:{
    // contentId:{
    //   handler(){
    //      if(this.contentId){
    //        console.log(this.contentId)
    //         this.init()
    //       }
    //   },
    //   deep:true,
    //   immediate: true
    // }
  },
  created(){
    this.init()
  },
  methods:{
    async init(){
        this.pubilcArr = []
        this.$toast.loading({
          message: '加载中',
          duration: 0, // 会一直存在,如果有下一个this.$toast被调用，它就会被覆盖，也就消失了
          mask: true
        });
        try {
          if(this.menuType == 'lanmu'){
            let data = await getChildColumn({
              "pageNumber": 1,
              "pageSize":90071992,
              "parentId": this.contentId
            })
            this.pubilcArr = data.data.data.rows
            this.$toast.clear();
          }else{
            let data = await getChildServiceColumn({
              "pageNumber": 1,
              "pageSize":90071992,
              "parentId": this.contentId
            })
            this.pubilcArr = data.data.data.rows
            this.$toast.clear();
          }
          
        } catch (error) {
          this.$toast.fail("加载失败");
        }
        
    }
  }
}
</script>
<style lang="less" scoped>
.nkdetails{}
</style>