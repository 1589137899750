<template>
  <div :class="state == 1?'fromText fromtab':'fromText'">
    <div v-if="contentData.length<1" style="position: relative;">
      <nodata></nodata>
    </div>
    <div :class="state == 1?'':'fromlist'">
      <van-search
        v-model="value"
        show-action
        placeholder="请输入搜索关键词"
        @search="onSearch"
      >
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
    </div>

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="load_more"
    >
    <div :class="state == 1?'':'boxFromlist'">
      <div class="boxFrom" v-for="i in contentData" :key="i.id" @click="btnDetails(i)" v-if="contentData.length>0">
      <div class="boxFromImage" v-if="listId == 1 || listId == 2">
        <img v-lazy="i.coverPhoto" alt="">
      </div>
      <div class="boxFromContent">
        <div class="boxFromTittle">{{i.title}}</div>
        <div class="boxFromTag" >
          <van-tag color="#E3F7FF" text-color="#0073C0" v-for="(item,index) in i.tagInfo" :key="index">{{item}}</van-tag>
        </div>
        <div class="boxFromTime" v-if="listId == 1 || listId == 3">{{i.publishTime}}</div>
      </div>
    </div>
    </div>
    </van-list>
  </div>
</template>

<script>
import {getContentByColumnId,getServiceContentByColumnId} from '@/api/home.js'
export default {
  props:['contentList1'],
  name:"fromtext",
  data() {
    return {
      id:'',
      list: [],
      loading: false,
      finished: false,
      value:'',
      contentData:[],
      typeId:'',
      page:1
    }
  },
  created(){
    this.id = this.$route.params.id

  },
  mounted(){
    if(this.$store.state.jxbApp){
      window.location.href=`ssptitle://${this.topTittle}`
    }else{
      document.title = this.topTittle
    }
    
  },
  watch:{
    contentList1(){
      // console.log('1')
      this.contentData = this.contentList1
    }
  },
  computed:{
    topTittle(){
      return this.$store.state.topTittle
    },
    tabId(){
      return this.$store.state.tabId
    },
    listId(){
      return this.$store.state.listId
    },
    detailsId(){
      return this.$store.state.detailsId
    },
    menuType(){
      return this.$store.state.menuType
    },
    state(){
      return this.$store.state.columnStyle
    },
  },
  methods:{
    load_more: function() {
      this.page += 1;//页数+1
      this.onLoad();
    },
    async onLoad(){
      if(this.state == 1){
        this.typeId = this.tabId
      }else{
        this.typeId = this.detailsId
      }
      if(this.menuType == 'lanmu'){
        let data = await getContentByColumnId({
          "columnId": this.typeId,
          "pageNumber": this.page,
          "pageSize": 10,
          "title": this.value
        })
        let arr = data.data.data.rows
          arr.map((item)=>{
            if(item.tagInfo){
              item.tagInfo = item.tagInfo.split(',')
            }
          })
         
          this.loading = false;
          if(this.contentData.length == data.data.data.total){
          
          }else{
            this.contentData = this.contentData.concat(arr);//追加数据
          }
          if (this.page == data.data.data.totalPage || this.page > data.data.data.totalPage) {  //数据全部加载完成
            this.finished = true;
          }else{
            this.finished = false;
          }        
      }else{
        let data = await getServiceContentByColumnId({
          "columnId": this.typeId,
          "pageNumber": this.page,
          "pageSize":10,
          "title": this.value
        })
        let arr = data.data.data.rows
        // console.log(arr)
          arr.map((item)=>{
            if(item.tagInfo){
              item.tagInfo = item.tagInfo.split(',')
            }
          })
        this.loading = false;
        if(this.contentData.length == data.data.data.total){
          
        }else{
          this.contentData = this.contentData.concat(arr);//追加数据
        }
        // console.log(this.contentData.length)
        if (this.page == data.data.data.totalPage || this.page > data.data.data.totalPage) {  //数据全部加载完成
          this.finished = true;
        }else{
          this.finished = false;
        }      
      }
    },
    async onSearch(){
      this.page = 1;//页数+1
      this.contentData = []
      this.finished = true
      this.onLoad();
      // if(this.state == 1){
      //   this.typeId = this.tabId
      // }else{
      //   this.typeId = this.detailsId
      // }
      // if(this.menuType == 'lanmu'){
      //   let data = await getContentByColumnId({
      //     "columnId": this.typeId,
      //     "pageNumber": 1,
      //     "pageSize": 10,
      //     "title": this.value
      //   })
      //   let arr = data.data.data.rows
      //     arr.map((item)=>{
      //       if(item.tagInfo){
      //         item.tagInfo = item.tagInfo.split(',')
      //       }
      //     })
      //   this.contentData = arr
      // }else{
      //   let data = await getServiceContentByColumnId({
      //     "columnId": this.typeId,
      //     "pageNumber":1,
      //     "pageSize":10,
      //     "title": this.value
      //   })
      //   let arr = data.data.data.rows
      //     arr.map((item)=>{
      //       if(item.tagInfo){
      //         item.tagInfo = item.tagInfo.split(',')
      //       }
      //     })
      //   this.contentData = arr
      // }
    },
    // 查看下一步
    btnDetails(item){
      this.$router.push({path:"/contentItems",query:{id:item.id}})
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.van-tag--default{
    margin-right: .144rem
  }

.boxFromContent{
  flex: 1;
  .boxFromTag{
    display: flex;
    margin-top: .249rem;
  }
}
.boxFromlist{
  margin-top: 1.778rem;
}
.fromText{
  padding: 0 .355556rem;
  box-sizing: border-box;
}
.fromtab{
 margin-top: 0.589rem;
}
.fromlist{
 position: fixed;
 top: 0;
 width: 100%;
 right: 0;
}
.boxFrom{
  display: flex;
  border-bottom: 1px solid #efecec;
  text-align: left;
  margin-bottom: .471rem;
  padding-bottom: .206667rem;
  box-sizing: border-box;
}
.boxFromTittle{
  font-size: .39rem;;
  color: #000000;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2;//控制行数
  overflow:hidden;
}
.boxFromImage{
  width: 3.022rem;
  height: 2.133rem;
  margin-right: .356rem;
  img{
    width: 100%;
    height: 93%;
  }
}
.boxFromTime{
  font-size: .302222rem;
  color: #92999F;
  margin: .266667rem 0;
}
</style>
