<template>
  <div class="list">
    <div v-if="pubilcArr.length<1" style="position: relative;">
      <nodata></nodata>
    </div>
    <div class="listNav" v-for="item in pubilcArr" :key="item.id"  @click="onlist(item)" v-else>
        <img v-lazy="item.columnIcon" alt="">
    </div>
  </div>
</template>
<script>
export default {
  name:"list",
  props:["pubilcArr"],
  data() {
    return {
      list:[
        {img:'https://img01.yzcdn.cn/vant/logo.png'},
        {img:'https://img01.yzcdn.cn/vant/logo.png'},
        {img:'https://img01.yzcdn.cn/vant/logo.png'},
        {img:'https://img01.yzcdn.cn/vant/logo.png'},
        {img:'https://img01.yzcdn.cn/vant/logo.png'},
        {img:require('../../assets/gdyx.png')},
        {img:require('../../assets/gdyx.png')},
        {img:require('../../assets/gdyx.png')}
      ],
      listArr:[]
    }
  },
  watch:{
    // pubilcArr:{
    //   handler(){
    //     console.log(this.pubilcArr)
    //     this.listArr = this.pubilcArr
    //   },
    //   deep:true,
    //   immediate:true
    // }
  },
  computed:{
    topTittle1(){
      return this.$store.state.topTittle
    },
  },
  created(){
    // console.log(this.pubilcArr)
  },
  mounted(){
    if(this.$store.state.jxbApp){
      window.location.href=`ssptitle://${this.topTittle1}`
    }else{
      document.title = this.topTittle1
    }
     
  },
  methods:{
    async onlist(item){
      if(item.columnType == 3){
        location.href = item.columnLink
          // lightAppJssdk.weex.openWeexVc({
          //   title :item.columnName,//顶部导航栏标题
          //   url : item.columnLink, //weex页面地址
          //   success: function(data) {
          //       console.log(data);
          //   },
          //   fail: function(data) {
          //       console.log('falied');
          //   }
          //   });
        }else{
          this.$store.commit('setTopTittle',item.columnName)
          // 首页数据
          this.$store.commit('setMenulist',item)
          // console.log(item)
          // 传递id
          this.$router.push({path:'/chooseList',query:{categoryId:item.id,columnType:item.columnType,columnAttr:item.columnAttr,columnStyle:item.columnStyle}})
        }

    }
  }
}
</script>
<style lang="less" scoped>
.list{
  .listNav{
    padding: 0 .408889rem;
    text-align: center;
    // width: 9.164rem;
    // margin: 0 auto;
    // height: 2.32rem;
    margin-bottom: .507rem;
    // text-align: left;
    // padding-left: .684rem;
    // padding-top: .513rem;
    box-sizing: border-box;
    // color: #FFFFFF;
    img{
      // width: 9.164rem;
      height: 2.32rem;
      margin: 0 auto;
    }
  }
  .listNav:nth-child(1){
    margin-top: .271rem;
  }
  .higherLearning{
    font-size: .444rem;
    font-weight: 500;
  }
  .talent{
    font-weight: 400;
    font-size: .356rem;
    margin-top: .256rem;
  }
}
</style>